body {
  height: 100%
}

html {
  height: 100%;
}

#app div {
  height: 100%
}

#root {
  height: 100%;
}

.overview {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr) 0.5fr 0.5fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 100%;
}

.questions {
  grid-area: 1 / 1 / 4 / 3;
  overflow: auto;

}


.leaderboard {
  grid-area: 5 / 3 / 7 / 6;
  box-shadow: 0 0 20px #0000002e;
  border-radius: 10px;
  overflow: auto;
  margin: 10px;
  display: flex;
  overflow-x: scroll;
}
.configure {
  grid-area: 4 / 1 / 7 / 3;

  margin: 10px;
  box-shadow: 0 0 20px #0000002e;
  border-radius: 10px;
}

.view-results {
  grid-area: 1 / 3 / 5 / 6;
  margin: 10px;
  box-shadow: 0 0 20px #0000002e;
  border-radius: 10px;
  scroll-snap-type: x mandatory;

  overflow: auto;
  display: flex;
  overflow-x: scroll;

}

.view-results > span {
  scroll-snap-align: center;

  min-width: 100%;
  padding: 20px;
}

.question-item {
  padding: 20px;
  border-bottom: 1px solid #d2d2d2;
  transition: background-color 0.3s cubic-bezier(0, 0, 0.67, 0.96);
  cursor: pointer;
  user-select: none;
}

.question-item:hover {
  background-color: #d2d2d2;
}

.question-item-index {
  padding-right: 12px;
  font-weight: 100;
}


.question-inner {
  margin: 10px;
}

.save-question-button {
  margin-top: 13px;
}

.question-inner-title {
  margin-bottom: 20px;
}


.canvas {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  margin: 20px;
  justify-content: center;
  width: 500px;
}

.p5Canvas {
  padding: 5px;
  margin: 20px;
  box-shadow: 0 0 20px 0px #46454536;
  border-radius: 10px;
}
.canvas-wrap {
  margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;

}
.answer-wrap {
  display: flex;
  flex-direction: column;
}

.change-points-btn {
  flex-grow: 1;
  margin: 0 10px;
}
.qanswer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 17pt;
}

.lb-group {
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 10px;
}

.form-group {
  margin-bottom: 0.5rem;
}